/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import { jambookURL } from "../../utils/variables"
//import InstaLogo from "../../../static/images/commonImg/instagram.svg"
import TwitterLogo from "../../../static/images/commonImg/twitter-logo.svg"
import AmazonLogo from "../../../static/images/commonImg/amazon-radius.svg"
import NoteLogo from "../../../static/images/commonImg/note-logo.svg"

const Writer = ({ path }) => {
    return (
        <div className="writer">
            <div className="img-text-container">
                <div className="img-container">
                    <StaticImage src="../../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                </div>
                <div className="text-container">
                    <p><span className="write-bold">この記事の筆者</span>：三好アキ</p>
                    <br/>
                    <p>🔹 専門用語なしでプログラミングを教えるメソッドに定評があり、1200人以上のビギナーを、最新のフロントエンド開発入門に成功させる。Amazonベストセラー1位を複数回獲得している『{/* <a href="https://www.amazon.co.jp/dp/B094Z1R281" rel="noopener noreferrer" target="_blank"> */}はじめてつくるReactアプリ with TypeScript{/* </a> */}』、『動かして学ぶ！Next.js/React開発入門（翔泳社／2024年）』著者。</p>
                    <br/>
                    <p>Amazon著者ページはこちら → <a href="https://www.amazon.co.jp/stores/author/B099Z51QF2" rel="noopener noreferrer" target="_blank">amazon.co.jp/stores/author/B099Z51QF2</a></p>
                    <a href="https://twitter.com/monotein_" rel="noopener noreferrer" target="_blank"><img src={TwitterLogo} alt="instagram" className="insta-logo"/></a>
                    <a href="https://www.amazon.co.jp/stores/author/B099Z51QF2" rel="noopener noreferrer" target="_blank"><img src={AmazonLogo} alt="AmazonLogo" className="insta-logo"/></a>
                    <a href="https://note.com/monotein" rel="noopener noreferrer" target="_blank"><img src={NoteLogo} alt="NotoLogo" className="insta-logo"/></a>
                    {/* <br/>
                    <br/>
                    <p>React、Next.js、TypeScriptなどのお役立ち情報や実践的コンテンツを、ビギナー向けにかみ砕いて無料配信中。登録はこちらから → <a href={`/register-newsletter?${path}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}> 無料メルマガ登録</a></p> */}
                    <br/>
                    <br/>
                    <p>🔹 UdemyでReact・JavaScript・Node.jsの入門動画を無料で公開中 → <a href="https://www.udemy.com/user/aki-miyoshi/" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}> 無料の入門動画</a></p>
                    <br/>
                   {/*  <EnqueryPart path={path} /> */}
                </div>
            </div>
        </div>
    )
}

const EnqueryPart = ({ path }) => {
    const [showForm, setShowForm] = useState(true)
    const [showLine, setShowLine] = useState(false)

    useEffect(() => {
        setShowForm(false)
    }, [])
    
    const openLineHandler = () =>{
        if(showForm){
            setShowLine(true)
            setShowForm(false)
        }else if(!showForm){
            setShowLine(true)
        }
    }

    const openFormHandler = () =>{
        if(showLine){
            setShowLine(false)
            setShowForm(true)
        }else if(!showLine){
            setShowForm(true)
        }
    }


    return (
        <div className="enqueryPart-root">
            <p>ウェブサイトやホームページに関するご質問、お悩みごとに無料でお答えします。インスタグラムのDM、ライン、連絡フォームなどからお気軽にどうぞ。<a href={`/blog/questions-and-answers-1/?r=${path}`} rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>質問例はこちら。</a> </p>
            <br/>
            <div className="new-flex-container btn-wrapper">
                <a href={`/cu3tfwv45sr9jvei9nm2/?r=ig-business=writer-form=${path}`} rel="noopener noreferrer" target="_blank">Instagram</a>
                <a onClick={() => openLineHandler()}>ライン</a>
                <a onClick={() => openFormHandler()}>連絡フォーム</a>
            </div>
            {showForm && <FreeEnqueryForm path={path} />}
            {showLine && <FreeEnqueryLine path={path} />}
        </div>
    )
}

const FreeEnqueryLine = ({ path }) => {
    return (
        <div className="freeEnqueryLine-root">
            <hr/>
            <ul>
                <li>1. <a href={`/cu3tfwv45sr9jvei9nm2/?r=line-business=writer-form=${path}`} rel="noopener noreferrer" target="_blank">コチラ</a>をクリック。</li>
                <li>2. 「友達に追加」を押してご連絡ください。</li>
                <li>* 下記QRコードからも「友達に追加」をご利用いただけます。</li>
                <StaticImage src="../../../static/images/commonImg/line-business-qr-code.png" alt="Line QR code" placeholder="blurred" />
            </ul>
        </div>
    )
}

const FreeEnqueryForm = ({ path }) => {
    return (
        <div className="freeEnqueryForm-root">
            <form name="FreeEnqueryFormSales" method="POST" data-netlify="true" action={`/success/?r=${path}`} data-netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="FreeEnqueryFormSales"/>
                <label htmlFor="name">お名前</label>  
                <input type="text" name="name" id="name" placeholder="三好アキ" required/>
                <label htmlFor="email">Eメール</label>
                <input type="email" name="email" id="email" placeholder="contact@monotein.com" required/>
                <label htmlFor="textarea">営業のご経歴など</label>
                <textarea name="message" rows="5" id="textarea" placeholder="営業パートナー募集について" required></textarea>
                <br/> 
                <button type="submit">送信</button>
            </form>
        </div>
    )
}

export { Writer, EnqueryPart, FreeEnqueryForm }